@layer modules, ui, base;
@layer base {
  .CardPageLink_main__oovmq {
  display: flex;
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  align-items: center;
}

.CardPageLink_content__JeZR1 {
  flex: 1;
  margin: 0 var(--spacer-200) 0 0;
}

.CardPageLink_wrapperImage__iqjxT {
  padding-right: var(--spacer-200);
}

.CardPageLink_link__Oi5uJ {
  color: var(--color-black);
  text-decoration: none;
}

.CardPageLink_link__Oi5uJ::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.CardPageLink_link__Oi5uJ:hover::before {
  content: "";
  display: block;
  position: absolute;
  z-index: var(--z-down-in-the-current-stacking-context); /* TODO: what is the current stacking context? */
  top: calc(var(--spacer-150) * -1);
  left: calc(var(--spacer-150) * -1);
  width: calc(100% + var(--spacer-300));
  height: calc(100% + var(--spacer-300));
  border-radius: var(--radius-200);
  background-color: var(--color-grey--050);
}

:not(.CardPageLink_wrapper__PvhDD) + .CardPageLink_wrapper__PvhDD .CardPageLink_subtitle___S3oI {
  color: var(--color-black);
  font-size: var(--subheadSmall);
  font-weight: var(--black);
  line-height: var(--subheadSmallLineHeight);
}

.CardPageLink_image__VRRad {
  display: block;
  width: 56px;
  height: 56px;
  overflow: hidden;
  border-radius: var(--radius-200);
}

@media (max-width: 47.999rem) {
  .CardPageLink_shortDescription__4iIi5 {
    display: none;
  }
}

@media (min-width: 48rem) {
  :not(.CardPageLink_wrapper__PvhDD) + .CardPageLink_wrapper__PvhDD .CardPageLink_subtitle___S3oI {
    font-size: var(--subhead);
    line-height: var(--subheadLineHeight);
  }

  .CardPageLink_image__VRRad {
    width: 86px;
    height: 86px;
  }
}

}
